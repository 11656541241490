import React, { useState, useEffect, useRef, useContext } from 'react';
import Isotope from 'isotope-layout';
import { UserContext } from '../UserContext';
import { SelectButton } from 'primereact/selectbutton';
import { SensorCard } from '../components/SensorCard';

//TODO: rename this to "sortTypes". I bastardized it.
const buildingTypes = [
	{ label: 'Sort by Priority', value: 'priority' },
	{ label: 'Sort by Location', value: 'location' },
	{ label: 'Sort by Thing Type', value: 'things' }
]

export const SensorCardsAGC = () => {
	const [cardData, setCardData] = useState([])
	const [cardTypeKey, setCardTypeKey] = useState('*');
	const [sectionTypeKey, setSectionTypeKey] = useState('*');
	const [buildingTypeKey, setBuildingTypeKey] = useState('priority');
	const [filterKey, setFilterKey] = useState('*');
	const [sortKey, setSortKey] = useState('priority');
	const [sortAsc, setSortAsc] = useState(true);
	const [buildingTypeButton, setBuildingTypeButton] = useState('priority');

	const user = useContext(UserContext);
	const isotope = useRef();

	useEffect(() => {
		let timer = null;

		fetchCardList();

		timer = setInterval(() => {
			fetchCardList()
		}, 60 * 1000);

		return () => {
			clearInterval(timer);
		}
	}, []);

	useEffect(()=>{
		isotope.current = new Isotope('.widgets', {
			itemSelector: '.filter-item',
			layoutMode: 'fitRows',
			getSortData: {
				priority: '.priority',
				things: '.things',
				location: '.location'

			}
		})
	},[cardData])
	// handling filter key change
	useEffect(() => {
		if (isotope.current) {
			//console.log('modifying key' + filterKey)

			let filtVal = (filterKey === '*' ? `*` : `.${filterKey}`)
			let sortVal = (sortKey === '*' ? `*` : `${sortKey}`)
			isotope.current.arrange({ sortBy: sortVal, filter: filtVal, sortAscending: sortAsc })
		}
	})

	useEffect(() => {
		let comboString;

		if (cardTypeKey !== '*' && sectionTypeKey !== '*') {
			comboString = cardTypeKey + "." + sectionTypeKey
		}
		else if (cardTypeKey === '*' && sectionTypeKey !== '*') {
			comboString = sectionTypeKey
		}
		else if (cardTypeKey !== '*' && sectionTypeKey === '*') {
			comboString = cardTypeKey
		}
		else {
			comboString='*'
		}

		setFilterKey(comboString);
		setSortKey(buildingTypeKey)
	}, [cardTypeKey, sectionTypeKey, buildingTypeKey])

	const fetchCardList = () => {
		user.apiCall(`sensorcards?airport=AGC`, "GET")
			.then((res) => {
				if(res.status !== 200) {
					user.showErrorToast("Card data failed to load");
					return;
				}

				setCardData(res.data);

				isotope.current = new Isotope('.widgets', {
					itemSelector: '.filter-item',
					layoutMode: 'fitRows',
					getSortData: {
						priority: '.priority',
						things: '.things',
						location: '.location'

					}
				})
			})
			.catch((error) => {
				user.showErrorToast("Card data failed to load");
			})
	}

	function handleBuildingKeyChange(key) {
		setBuildingTypeKey(key);
		setBuildingTypeButton(key);
		setSortAsc(sortAsc === true ? false : true);
	}

	return (
		<div id="DataCardsPage">
			<div className="p-d-flex">
				{/*<Button onClick={handleRestAllKeyChange('*')} label="Show All" />*/}
				<div className="button-container">
					<div className="slicer data-building-slicer">
						<SelectButton value={buildingTypeButton}
							options={buildingTypes}
							onChange={(e) => handleBuildingKeyChange(e.value)}
						/>
					</div>
				</div>
			</div>

			<div className="widgets card" id="Super">
				<div className="p-col-12">
					<div className="everything">
						{cardData && cardData.map((thisCard, idx) => (
							<div key={idx} >
								<SensorCard data={thisCard} />
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}
