import React from 'react';
import { format, addMinutes } from 'date-fns';
import XbridgeCard from '../img/xbridgewhite.png'

const dtFmtStr = "MM/dd\xa0hh:mm";
const dict = {
	"Flight": " flight",
	"Parking": " parking",
	"TSA": " tsa",
	"Restroom": " restroom",
	"APM": " flow apm-to-airside",
	"Gate": " restroom",
	"xBridge": " restroom",
	"Bus": " flow apm-to-airside",
	"Seats": " restroom",
	"Falls": " restroom",
	"Luggage Left": " restroom",
	"Temps": " temps"
};

export const SensorCard = (props) => {
	const cardContent = (props) => {
		if (props.cardType === "xBridge") {
			return (
				<span className='title xBridgeTitle'>
					<i className="pi pi-xBridge"><img className='xBridgeImg'
						src={XbridgeCard}
						alt="" />
					</i>{props.cardType}
				</span>
			);
		}

		let icon = "";
		let text = "";

		if (props.cardType === "Flight" && props.cardTypeDirection === "Departing") {
			icon = "pi-flight-departure";
			text = `Flight ${props.cardName}`;
		}
		else if (props.cardType === "Flight" && props.cardTypeDirection === "Incoming") {
			icon = "pi-flight-arrival";
			text = `Flight ${props.cardName}`;
		}
		else if (props.cardType === "APM") {
			icon = "pi-users";
			text = "APM";
		}
		else if (props.cardType === "Restroom" && props.cardTypeDirection === "Mens") {
			icon = "pi-restroom-mens";
			text = "Restroom";
		}
		else if (props.cardType === "Restroom" && props.cardTypeDirection === "Womens") {
			icon = "pi-restroom-womens";
			text = "Restroom";
		}
		else if (props.cardType === "Restroom" && props.cardTypeDirection === "Assisted") {
			icon = "pi-restroom-assisted";
			text = "Restroom";
		}
		else if (props.cardType === "Restroom" && (props.cardTypeDirection === 'Gate' || props.cardTypeDirection === 'Door')) {
			icon = "pi-gates";
			text = props.cardTypeDirection;
		}
		else if (props.cardType === "Parking") {
			icon = "pi-parking-white";
			text = props.cardName === "Extended Term" ? "Extended" : props.cardName;
		}
		else if (props.cardType === "TSA") {
			icon = "pi-clock";
			text = props.cardType;
		}
		else if (props.cardType === "Bus") {
			icon = "pi-car";
			text = props.cardType;
		}
		else if (props.cardType === "Gate") {
			icon = "pi-gates";
			text = props.cardType;
		}
		else if (props.cardType === "Temps") {
			icon = "pi-bars";
			text = "Temperature";
		}
		else {
			icon = "pi-question";
			text = "Unknown";
		}

		return (
			<>
				<i className={`pi ${icon}`}></i>
				<span className="title">{text}</span>
			</>
		);
	}

	function textLine1(props) {
		let endTXT;
		if (props.cardType === "Flight") {
			return (<div className="overview-box-count">{props.cardGate} <span>{endTXT}</span></div>);
		}
		else if (props.cardType === "TSA") {
			endTXT = "min"
		}
		else if (props.cardType === "Parking") {
			endTXT = "%"
		}
		else if (props.cardType === "Seats") {
			endTXT = ""
		}
		else if (props.cardType === "Temps") {
			// Degree symbol
			endTXT = "\u00B0F";
		}
		else {
			endTXT = "ppl"
		}

		return (
			<div className="overview-box-count">				
				{endTXT === "ppl" ? props.cardNumberBig.toFixed(0) : props.cardNumberBig.toFixed(1)} <span>{endTXT}</span>
			</div>
		);
	}

	function textLine2(props) {
		if (props.cardType === "Flight" && props.cardTypeDirection === "Departing") {
			return (
				<div className="overview-box-stats">
                    Departing in {Math.floor(Math.abs(props.cardNumberSmall))} min @ <strong>{props.cardGate}</strong>
				</div>
			);
		}
		else if (props.cardType === "Flight" && props.cardTypeDirection === "Incoming") {
			return (
				<div className="overview-box-stats">
                    Arrived {Math.floor(Math.abs(props.cardNumberSmall))} min ago @ <strong>{props.cardGate}</strong>
				</div>
			);
		}
		else if (props.cardType === "APM") {
			return (
				<div className="overview-box-stats">
                    in the last 30 min went {props.cardName.startsWith("Landside") ? "in landside" : "airside"}
				</div>
			);
		}
		else if (props.cardType === "Restroom" || props.cardType === "Gate" || props.cardType === "xBridge") {
			const boldStyle = props.cardName.length >= 15 ? {fontSize: "10px"} : {}
			return (
				<div className="overview-box-stats">
                    in last 30 min @ <strong style={boldStyle}>{props.cardName}</strong>
				</div>
			);
		}
		else if (props.cardType === "Parking") {
			return (<div className="overview-box-stats"> full</div>);
		}
		else if (props.cardType === "TSA") {
			return (<div className="overview-box-stats"> wait @ <strong>{props.cardName}</strong></div>);
		}
		else if (props.cardType === "Bus") {
			return (<div className="overview-box-stats"> in last 30 min <strong>{props.cardName}</strong></div>);
		}
		else if (props.cardType === "Temps") {
			const dt = new Date(props.cardNumberSmall);
			let spanProps = {};
			if(addMinutes(dt, 90) < Date.now()) {
				spanProps.className = "old";
			}

			return (
				<div className="overview-box-stats">
					<strong>{props.cardGate}</strong>
					{" "}
					<span>
						{props.cardTypeDirection}<br />
						Updated{" "}<span {...spanProps}>{format(dt, dtFmtStr)}</span>
					</span>
				</div>
			);
		}
	}

	function cardType2CardClass(props) {
		//grab translated value from dict
		const firstPart = `widget-overview${dict[props.cardType]} ${props.airport?.toLowerCase()}`;
		const secondPart = calculateStatusCombineWithFlight(props);
		const thirdPart = (props.cardType === "Restroom" || props.cardType === "Temps" ? " clickable" : " test")
		// now add the status such as low or departing
		return firstPart + " " + secondPart + thirdPart;
	}

	function calculateStatusCombineWithFlight(props) {
		if (props.cardType === "Flight") {
			return props.cardTypeDirection.toLowerCase();
		}

		return calculateStatusCombine(props);
	}

	function calculateStatusCombine(props) {
		let outputString;
		if (props.cardType === "APM") {
			outputString = calculateStatusPplMovers(props.cardNumberBig);
		}
		else if (props.cardType === "Restroom" || props.cardType === "Gate" || props.cardType === "xBrigde") {
			outputString = calculateStatusRestrooms(props.cardNumberBig);
		}
		else if (props.cardType === "Parking") {
			outputString = calculateStatusParking(props.cardNumberBig);
		}
		else if (props.cardType === "TSA") {
			outputString = calculateStatusTsa(props.cardNumberBig);
		}
		else if (props.cardType === "Temps") {
			outputString = calculateStatusTemps(props.cardNumberBig);
		}
		else {
			outputString = "low";
		}

		return outputString;
	}

	function calculateStatusPplMovers(recentPeopleCount) {
		if (recentPeopleCount < 150) {
			return "low"
		}
		else if (recentPeopleCount < 250) {
			return "medium"
		}
		else {
			return "high"
		}
	}

	function calculateStatusRestrooms(recentPeopleCount) {
		if (recentPeopleCount <= 10) {
			return "low"
		}
		else if (recentPeopleCount <= 25) {
			return "medium"
		}
		else {
			return "high"
		}

	}

	function calculateStatusParking(lotPercent) {
		if (lotPercent < 60) {
			return "low"
		}
		else if (lotPercent < 80) {
			return "medium"
		}
		else {
			return "high"
		}

	}

	function calculateStatusTsa(waitTime) {
		if (waitTime < 10) {
			return "low"
		}
		else if (waitTime < 20) {
			return "medium"
		}
		else {
			return "high"
		}
	}

	const calculateStatusTemps = (degrees) => {



		if(degrees < 23){
			return "lowest"
		}
		if(degrees < 28){
			return "lower"
		}
		if(degrees < 33){
			return "low"
		}
		return "normal"
	}

	function filterClassName(props) {
		return (
			"filter-item " +
            props.cardType + " " +
            props.section + " " +
            props.building + " " +
            (props.cardGate.includes('Core') ? "Core " : "") +
            props.airport.toLowerCase() + " " +
            calculateStatusCombine(props)
		);
	}

	function prioritySort(props) {
		switch(calculateStatusCombine(props)){
		case 'high': return 'a';
		case 'medium': return 'b';
		case 'low': return 'c';
		default: return 'c'
		}
	}

	function thingsSort(props) {
		switch(props){
		case 'TSA': return 'a';
		case 'Parking': return 'b';
		case 'APM': return 'c';
		case 'Flight': return 'd';
		case 'Restroom': return 'e';
		case 'Gate': return 'f';
		case 'xBridge': return 'g';
		default: return 'h'
		}
	}

	function locationsSort(props) {
		if(props.cardGate.includes('Core')){
			return 'a'
		}
		switch(props.section){
		case 'A': return 'b';
		case 'B': return 'c';
		case 'C': return 'd';
		case 'D': return 'e';
		case 'Baggage': return 'f';
		case 'Transit': return 'g';
		case 'Ticketing': return 'h';
		case 'Parking': return 'i';
		default: return 'j'
		}
	}

	return (
		<div className={filterClassName(props.data)} >
			<div className={cardType2CardClass(props.data)}>
				<div className="overview-box-title" style={{maxHeight: "21.5px"}}>
					{cardContent(props.data)}
				</div>
				{textLine1(props.data)}
				{textLine2(props.data)}
			</div>
			<div className='priority'>{prioritySort(props.data)}</div>
			<div className='things'>{thingsSort(props.data.cardType)}</div>
			<div className='location'>{locationsSort(props.data)}</div>
		</div>
	)
}
