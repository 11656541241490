import React, { useEffect, useState, useContext} from 'react';
import { Card } from 'primereact/card';
import { ProgressSpinner } from "primereact/progressspinner";
import { Paginator } from 'primereact/paginator';
import { Button } from 'primereact/button';
import { AcaaContext } from "../utilities/AcaaContext";

export const Notams = (props) => {
	const [basicFirst, setBasicFirst] = useState(0);
	const [basicRows, setBasicRows] = useState(5);
	const [data, setData] = useState([]);
	const global = useContext(AcaaContext);

	useEffect(() => {
		console.log('effect')
		let dataArray = []
		props.data.slice(basicFirst,basicFirst + basicRows).forEach((record,index) => {
			dataArray.push(
				<div key={"Notam"+index}>
					<b>{record.notamNumber}</b>{` ${record.notamText} `}
					{formatTimestamp(record.effectiveStartDtUtc)}{" UNTIL "}
					{formatTimestamp(record.effectiveEndDtUtc)}{" CREATED "}
					{formatTimestamp(record.issuedDtUtc)}<hr/>
				</div>
			);
		})
		setData(dataArray)
	}, [basicFirst, basicRows, props.data]);
	function formatTimestamp(timestamp) {
		var d = new Date(timestamp);
		return `${d.getUTCDate()} ${monthString(d.getUTCMonth())}  ${d.getUTCHours()}:` + (d.getUTCMinutes() < 10 ? "0" + d.getUTCMinutes() : d.getUTCMinutes());
	}

	function monthString(m){
		switch(m){
		case 0: return "JAN";
		case 1: return "FEB";
		case 2: return "MAR";
		case 3: return "APR";
		case 4: return "MAY";
		case 5: return "JUN";
		case 6: return "JUL";
		case 7: return "AUG";
		case 8: return "SEP";
		case 9: return "OCT";
		case 10: return "NOV";
		case 11: return "DEC";
		default: return "UNK";
		}
	}

	const onBasicPageChange = (event) => {
		setBasicFirst(event.first);
		setBasicRows(event.rows);
	}

	if (!props.data) {
		return (
			<Card id='notams'
				title={<div ><div style={{display: "inline-block",fontSize: '26px',fontWeight: '350', color: '#146fd7',paddingBottom: 5}}>NOTAMS</div></div>}
				style={{ position: 'relative', float: 'right', padding: 0, paddingTop: 0, marginTop: 5, marginLeft: 5, zIndex: 3, width: 300,}}>
				<ProgressSpinner
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						width: "250px",
						height: "250px",
					}}
					strokeWidth="1"
					animationDuration="1s"
				/>
			</Card>
		);
	}
	else {
		return (
			<Card id='notams' className={'notamsCard '+ (global.menuActive == true ? " menuState" : "")}
				title={<div ><div style={{display: "inline-block",fontSize: '26px',fontWeight: '350', color: '#146fd7',paddingBottom: 5}}>NOTAMS</div></div>}>
				<div style={{position: 'relative',overflowY: 'auto', height: '35vh' }}>
					{data}
				</div>
				<Paginator
					style={{position: 'block',marginLeft: 'auto',marginRight: 'auto',height: '10vh', width: '100%'}}
					first={basicFirst}
					rows={basicRows}
					totalRecords={props.data.length}
					onPageChange={onBasicPageChange}
					template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords} NOTAMS"
				/>

			</Card>
		);
	}

}
