import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import AppSettings from "../appsettings.json";
import { InputSwitch } from 'primereact/inputswitch';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import mapboxgl from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '../components/MapComponent.css';
mapboxgl.accessToken = AppSettings.MapboxToken;

const defaultZoom = 15;
const defaultCenterPit = [-80.2463, 40.4955];
const centerAgc = [-79.9298, 40.3539];

/*
    mapRef
    startZoom
    startCenter
    showJumpers
	circles: [{ mid: [], mi, km }, ...]
    iconList: [{ location, name }, ...]
*/
export const AcaaMap = (props) => {
	const [mapSwitch, setMapSwitch] = useState(false);
	const [secretCount, setSecretCount] = useState(0);
	const [secretCoords, setSecretCoords] = useState(defaultCenterPit);

	const mapContainer = useRef(null);

	if(document.getElementsByClassName('layout-main')[0]){
		const resizeObserver = new ResizeObserver(() => {
			if(props.mapRef.current){
				props.mapRef.current.resize();
			}
		});
		resizeObserver.observe(document.getElementsByClassName('layout-main')[0]);
	}

	useEffect(() => {
		if(props.mapRef.current) {
			return;
		}
		props.mapRef.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/mapbox/light-v10',
			center: props.startCenter ?? defaultCenterPit,
			zoom: props.startZoom ?? defaultZoom,
			maxBounds: props.maxBounds
		});

		props.mapRef.current.on('move', () => {
			const lngMove = props.mapRef.current.getCenter().lng.toFixed(4);
			const latMove = props.mapRef.current.getCenter().lat.toFixed(4);
			setSecretCoords([lngMove, latMove]);
		})

		if(props.circles !== null && props.circles !== undefined && props.circles.length > 0) {
			props.circles.forEach((val) => {
				const radius = val.km ?? val.mi * 1.6;
				props.mapRef.current.on('load', () => {
					props.mapRef.current.addSource(`circle${radius}`, drawCircle(val.mid, radius));
					props.mapRef.current.addSource(`symbol${radius}`, drawSymbol(val.mid, radius, val.mi));
					props.mapRef.current.addLayer({
						"id": `circle${radius}`,
						"type": "line",
						"source": `circle${radius}`,
						"layout": {},
						"paint": {
							"line-color": "#000",
							"line-width": 1,
							"line-opacity": 0.9
						}
					});
					props.mapRef.current.addLayer({
						"id": `symbol${radius}`,
						"type": "symbol",
						"source": `symbol${radius}`,
						"layout": {
							'text-field': ['get', 'description'],
							'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
							'text-radial-offset': 0.5,
							'text-justify': 'auto',
							'text-size': 14,							
						},
						"paint": {
							'text-opacity': 0.8
						}
					});
				})
			});
		}

		loadIcons();
	}, []);

	const changeMapStyle = (mapStyleId) => {
		//setMapSwitch(isSatellite);



		let currentStyle = props.mapRef.current.getStyle();
		console.log(currentStyle);
		axios.get(`https://api.mapbox.com/styles/v1/mapbox/${mapStyleId}?access_token=${AppSettings.MapboxToken}`)
			.then((res) => {
				let newStyle = res.data;
				// ensure any sources from the current style are copied across to the new style
				newStyle.sources = Object.assign({}, currentStyle.sources, newStyle.sources);

				const appLayers = currentStyle.layers.filter((el) => {
					// app layers are the layers to retain, and these are any layers which have a different source set
					return (el.source && el.source !== "mapbox" && el.source !== "composite");
				});
				//console.log(currentStyle.layers, newStyle.layers);

				newStyle.layers = [...newStyle.layers, ...appLayers];
				props.mapRef.current.setStyle(newStyle); // now setStyle

				loadIcons();
			});
	}

	const loadIcons = () => {
		if(props.iconList) {
			props.iconList.forEach((val) => {
				props.mapRef.current.loadImage(val.location, (error, image) => {
					if (error) {
						console.error(error)
					}
					else {
						props.mapRef.current.addImage(val.name, image);
					}
				});
			})
		}
	}

	const jumpMap = (coords, zoom) => {
		if(!coords || coords.length !== 2) {
			return;
		}

		let flyVals = {
			center: coords,
			zoom: props.startZoom ?? defaultZoom
		};
		if(zoom && typeof(parseInt(zoom)) === "number") {
			flyVals.zoom = parseInt(zoom);
		}

		props.mapRef.current.flyTo(flyVals);
	}

	// I have no idea what these static numbers are. Taken from a previous implementation
	const drawCircle = (circleMid, radiusInKm) => {
		const points = 64;

		const lat = circleMid[1];
		const lon = circleMid[0];

		let ret = [];
		const distanceX = radiusInKm / (111.320 * Math.cos(lat * Math.PI / 180));
		const distanceY = radiusInKm / 110.574;

		let theta, x, y;
		for(let i = 0; i < points; i++) {
			theta = (i / points) * (2 * Math.PI);
			x = distanceX * Math.cos(theta);
			y = distanceY * Math.sin(theta);

			ret.push([lon + x, lat + y]);
		}
		ret.push(ret[0]);

		return {
			"type": "geojson",
			"data": {
				"type": "Feature",
				"geometry": {
					"type": "Polygon",
					"coordinates": [ret]
				},
				"properties": {
					"description": radiusInKm.toFixed(1)
				}
			}
		};
	};

	const drawSymbol = (circleMid, radiusInKm, miles) => {

		let circleMidCopy = JSON.parse(JSON.stringify(circleMid));
		let lon = circleMidCopy[0];
		let lat = circleMidCopy[1];
		lon = lon + ((radiusInKm - 0.8) / (111.320 * Math.cos(lat * Math.PI / 180)))

		circleMidCopy[0] = lon
		console.log(radiusInKm)
		return {
			"type": "geojson",
			"data": {
				"type": "Feature",
				"geometry": {
					"type": "Point",
					"coordinates": circleMidCopy
				},
				"properties": {
					"description": miles
				}
			}
		};
	};

	const secretClick = () => {
		setSecretCount((secretCount + 1) % 7);
	}

	return(
		<>
			<div id="map"  ref={mapContainer} />
			{/*<div id='MapStyleSelector'>
				<span className='map-style-select' style={{backgroundColor: '#CAD2D3'}} onClick={() => changeMapStyle("light-v11")}></span>
				<span className='map-style-select' style={{backgroundColor: '#494E5A'}} onClick={() => changeMapStyle("dark-v11")}></span>
				<span className='map-style-select' style={{backgroundColor: '#6D9C4B'}} onClick={() => changeMapStyle("satellite-streets-v12")}></span>
			</div>*/}		
			<Card className="comfy" id="MapBase">
				<div className="switch-text" onClick={() => secretClick()}>
					Map Style
				</div>
				<InputSwitch checked={mapSwitch} onChange={(e) => changeMapStyle(e.value)} />
				{props.showJumpers && (
					<div className="jump-btns">
						<Button label="PIT" onClick={() => jumpMap(defaultCenterPit)} />
						<Button label="AGC" onClick={() => jumpMap(centerAgc)} />
					</div>
				)}
				{secretCount === 6 && (
					<div>
						{secretCoords[0]}, {secretCoords[1]}
					</div>
				)}
			</Card>
		</>
	);
}