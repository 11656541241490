import React, { useEffect, useState, useContext, useRef } from 'react';
import { UserContext } from '../UserContext.js';
import { AcaaContext } from "../utilities/AcaaContext";
import AppSettings from "../appsettings.json";
import { format } from 'date-fns';
import 'chartjs-adapter-date-fns';
import { Button } from 'primereact/button';
import { AcaaMap } from '../components/AcaaMap.js';
import { AcaaTimeline } from '../components/AcaaTimeline.js';
import { Notams } from '../components/Notams.js';
import mapboxgl from '!mapbox-gl';

mapboxgl.accessToken = AppSettings.MapboxToken;

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	TimeScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, TimeScale, PointElement, LineElement, Title, Tooltip, Legend);

const mapCenter = [-79.6149, 39.7171];
const agcCenter = [-79.9298, 40.3549];
const zoom = 5.5;
const innerZoom = 14.3;
const circleSet = [
	{mid: agcCenter, mi: 5},
	{mid: agcCenter, mi: 13},
	{mid: agcCenter, mi: 17}
]
const iconImages = [
	{ location: "assets/icons/icon-flightaware-med-black.png", name: "aircraft-flight-icon" },
	{ location: "assets/icons/icon-flights-blue.png", name: "agc-flight-icon" },
	{ location: "assets/icons/icon-flights-transparent.png", name: "external-flight-icon" }
]

export const StatusMapAGC = () => {
	const [weatherData, setWeatherData] = useState([]);
	const [host, setHost] = useState();
	const [sensorMarkers, setSensorMarkers] = useState([]);
	const [timelineData, setTimelineData] = useState({datasets: []});
	const [schedule, setSchedule] = useState([]);
	const [notamsData, setNotamsData] = useState([]);
	const [flightLayerOn, setFlightLayerOn] = useState(false);
	const [weatherTime, setWeatherTime] = useState(null);
	const [mapVisible, setMapVisible] = useState(true);
	const [notamsVisible, setNotamsVisible] = useState(true);

	const user = useContext(UserContext);
	const global = useContext(AcaaContext);
	const map = useRef(null);
	const innerMap = useRef(null);
	const mapLoaded = useRef(false); // mapbox isLoaded inconsistent
	const weatherInterval = useRef(null);
	const frameRef = useRef(0);

	useEffect(() => {
		let repeat;
		let wRepeat;
		let nRepeat;
		initializeLayers();
		fetchData();
		fetchWeather();
		fetchNotams();
		// Check layout-main and erase padding
		const holder = document.getElementsByClassName("layout-main");
		holder[0].classList.add("erase-padding");

		try {
			repeat = setInterval(fetchData, 60 * 1000);
			wRepeat = setInterval(fetchWeather, 5 * 60 * 1000);
			nRepeat = setInterval(fetchNotams, 5 * 60 * 1000)
		}
		catch (error) {
			console.error(error.message)
		}

		assembleMarker("10", [-79.9417, 40.3543], "notam-marker");
		assembleMarker("13", [-79.9347, 40.3576], "notam-marker");
		assembleMarker("28", [-79.9173, 40.3543], "notam-marker");
		assembleMarker("31", [-79.9221, 40.3517], "notam-marker");

		return () => {
			clearInterval(repeat);
			clearInterval(wRepeat);
			clearInterval(nRepeat);
			if(weatherInterval.current !== null && weatherInterval.current !== undefined) {
				clearInterval(weatherInterval.current);
			}

			// Reset layout-main padding for other pages
			const holder = document.getElementsByClassName("layout-main");
			holder[0].classList.remove("erase-padding");
		}
	}, []);

	useEffect(() => {
		const hostExists = (host !== null && host !== undefined);
		const weatherExists = (weatherData !== null && weatherData !== undefined && weatherData.length > 0);

		if( hostExists && weatherExists && mapLoaded.current) {
			handleWeatherLayers(weatherData, host);
		}
	},[weatherData, host])

	useEffect(() => {
		if(flightLayerOn) {
			map.current.resize();
			innerMap.current.resize();
		}
	}, [flightLayerOn])

	const fetchData = () => {
		user.apiCall("agcMap")
			.then((res) => {
				handleSensorData(res.data.statusSensors);
				setTimelineData({
					departures: res.data.departures,
					arrivals: res.data.arrivals
				});
				setSchedule(res.data.schedule)
				try {
					const notamList = handleNotams(res.data.notams);
				}
				catch(ex) {
					console.error("Notam failure");
					console.error(ex);
				}
			})
			.catch((ex) => console.error(ex.message));
	}

	const fetchWeather = () => {
		fetch("https://api.rainviewer.com/public/weather-maps.json")
			.then(res => res.json())
			.then(apiData => {
				const framesPastHour = apiData.radar.past.length - 6;
				setWeatherData(apiData.radar.past.slice(framesPastHour));
				setHost(apiData.host);
			})
			.catch(() => console.error("Weather error nonsense"));
	}

	const fetchNotams = () => {
		user.apiCall(`notamNow/AGC`)
			.then((res) => {
				console.log(res.data)
				setNotamsData(res.data);
			});
	}

	const handleWeatherLayers = (wData, wHost) => {
		wData.forEach((frame, idx) => {
			if (map.current.getLayer(`weather_${idx}`) !== undefined) {
				map.current.removeLayer(`weather_${idx}`).removeSource(`weather_${idx}`);
			}

			map.current.addLayer({
				id: `weather_${idx}`,
				type: "raster",
				source: {
					id: `weather_${idx}`,
					type: "raster",
					tiles: [
						wHost + frame.path + "/256/{z}/{x}/{y}/4/1_1.png"
					],
					tileSize: 256
				},
				layout: { visibility: "none" },
				minzoom: 0,
				maxzoom: 20
			});
		});
		clearInterval(weatherInterval.current);
		startWeatherAnimate(wData)
	}

	const startWeatherAnimate = (wData) => {
		if(weatherInterval.current !== null && weatherInterval.current !== undefined) {
			clearInterval(weatherInterval.current);
		}
		weatherInterval.current = setInterval(() => {
			wData.forEach((frame, idx) => {
				map.current.setLayoutProperty(
					`weather_${idx}`,
					"visibility",
					(idx === frameRef.current) ? "visible" : "none"
				);
				map.current.setPaintProperty(
					`weather_${idx}`,
					"raster-opacity",
					0.7
				);

			});
			frameRef.current = (frameRef.current + 1) % wData.length;
			setWeatherTime(wData[frameRef.current].time.toString() * 1000)
		}, 1000);
	}

	const handleSensorData = (sensorList) => {
		setSensorMarkers((prev) => {
			prev.forEach((m) => m.remove());
			let newMarkers = [];
			sensorList.forEach((sens) => {
				newMarkers.push(assembleMarker(sens.value, [sens.lon, sens.lat], "sensor-marker",sens.lastUpdated, sens.trend));
			});
			return newMarkers;
		});
	}

	const handleNotams = (notamList) => {
		let dataArray = [];
		notamList?.forEach((val) => {
			if(val.isClosed && val.geometryTypeId === 'Polygon') {
				let obj = {};
				let splitValues = val.geoLocation.split(',');

				let newArray = [];
				splitValues?.forEach((elem) => {
					newArray.push(elem.trim().split(' '))
				});
				newArray.pop();
				obj.geoLocation = newArray;
				obj.notamNumber = val.notamNumber;

				dataArray.push(obj);
			}
		});

		return dataArray;
	}

	const assembleMarker = (text, coords, type, lastUpdated, trend) => {
		const el = document.createElement("div");
		const elMarker = document.createElement("div");
		const elIcon = document.createElement("div");
		const elTrend = document.createElement("div");
		if(trend == "up"){
			elTrend.className= "fa-solid fa-caret-up elIcon"
		}
		else if(trend == "down"){
			elTrend.className= "fa-solid fa-caret-down elIcon"
		}
		else{
			elTrend.className= "fa-solid fa-hyphen elIcon"
		}
		let bColor = '#708090';
		if(type === "sensor-marker" ){
			bColor = '#537dbf';
			if(text < 33){
				bColor = '#69b365'
			}
			if(text < 28){
				bColor = '#f1b618'
			}
			if(text < 23){
				bColor = '#e46d6d'
			}
			text = text.toFixed(1)
			let now = new Date().getTime();
			let timestamp = new Date(lastUpdated).getTime();
			let diff = Math.round((now - timestamp) / 60000);
			if(diff > 15){
				elIcon.className= "pi pi-exclamation-triangle"
				el.appendChild(elIcon);
			}
			el.appendChild(elTrend);
		}
		elMarker.innerText = text;
		elMarker.className = type;
		elMarker.style.backgroundColor = bColor;

		el.appendChild(elMarker)
		const marker = new mapboxgl.Marker(el)
			.setLngLat(coords)
			.addTo(innerMap.current);

		const markerDiv = marker.getElement();
		markerDiv.addEventListener("click",(e) => {
			e.stopPropagation();
		});

		return marker;
	}

	const initializeLayers = () => {
		map.current.on("load", async () => {
			fetch("https://api.rainviewer.com/public/weather-maps.json")
				.then(res => res.json())
				.then(apiData => {
					const framesPastHour = apiData.radar.past.length - 6;
					const wData = apiData.radar.past.slice(framesPastHour);

					handleWeatherLayers(wData, apiData.host);

					map.current.resize();
					innerMap.current.dragRotate.disable();
					innerMap.current.touchZoomRotate.disableRotation();
					innerMap.current.resize();
					startWeatherAnimate(wData);
				});

			mapLoaded.current = true;
		});
	}

	return (
		<span id="AgcStatusMap" className={`${flightLayerOn ? "hidden" : ""}`}>
			{flightLayerOn && (
				<iframe src="https://globe.adsbexchange.com?airport=KAGC&temptrails=90" title="ADSB Data Over AGC" />
			)}
			<AcaaMap mapRef={map}
				startCenter={mapCenter}
				startZoom={zoom}
				circles={circleSet}
				iconList={iconImages}
			/>
			<div className="map-filters">
				<Button label="Weather"
					onClick={() => setFlightLayerOn(false)}
				/>
				<Button label="Flights"
					onClick={() => setFlightLayerOn(true)}
				/>
				{!flightLayerOn && (
					<div id="TimeCard">
						{new Date(weatherTime).toLocaleString()}
					</div>
				)}
			</div>
			<span style={{display: ((notamsVisible == true && flightLayerOn == false) ? "block" : "none")}}>
				<Notams data={notamsData}/>
			</span>
			<Button className="layer-button"
				style={{position: "absolute", zIndex: 999, bottom: 140, backgroundColor: "white", left: (global.menuActive == true ? 275 : 40), display: flightLayerOn ? " none" : "inline-flex"}}
				onClick={() => setNotamsVisible(notamsVisible == true ? false : true)}
				aria-label="Filter"
				rounded
				text
				raised
			>
				<i style={{marginRight: '2px'}} className={notamsVisible == true ? "pi pi-arrow-down-left" : "pi pi-arrow-up-right"}/>
			</Button>
			<div style={{display: ((mapVisible == true && flightLayerOn == false) ? "block" : "none")}} className="overlay inner-map">
				<AcaaMap mapRef={innerMap}
					startCenter={agcCenter}
					startZoom={innerZoom}
					maxBounds={[[-79.973327,40.342030],[-79.891122,40.367209]]}
				/>
			</div>
			<Button className="layer-button"
				style={{position: "absolute", zIndex: 999, bottom: 140, backgroundColor: "white",right: 35, display: flightLayerOn ? "none" : "inline-flex"}}
				onClick={() => setMapVisible(mapVisible == true ? false : true)}
				aria-label="Filter"
				rounded
				text
				raised
			>
				<i style={{marginRight: '2px'}} className={mapVisible == true ? "pi pi-arrow-down-right" : "pi pi-arrow-up-left"}/>
			</Button>
			<div className={"overlay timeline" + (global.menuActive == true ? " menuState" : "")}>
				<AcaaTimeline schedule={schedule}/>
			</div>
		</span>
	);
};
