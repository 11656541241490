import React, { createContext, useState } from "react";

const AcaaContext = createContext();

const AcaaContextProvider = ({ children }) => {
	const [menuActive, setMenuActive] = useState(window.innerWidth > 1024 ? true : false);

	return (
		<AcaaContext.Provider value={{menuActive: menuActive, setMenuActive: setMenuActive}}>
			{children}
		</AcaaContext.Provider>
	)
}

export { AcaaContext, AcaaContextProvider };