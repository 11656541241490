import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import { Route, Routes } from 'react-router-dom';
import { UserContext } from './UserContext';
import { AcaaContext } from './utilities/AcaaContext';
import AppTopBar from './AppTopbar';
import AppFooter from './AppFooter';
import AppMenu from './AppMenu';

import { Dashboard } from './pages/Dashboard';
import { StatusMapAGC } from './pages/StatusMapAGC';
import { SensorCardsAGC } from './pages/SensorCardsAGC';

import PrimeReact from 'primereact/api';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

const App = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [menuMode, setMenuMode] = useState('static');
  const [topbarMenuActive, setTopbarMenuActive] = useState(false)
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [configActive, setConfigActive] = useState(false);
  const [activeTopbarItem, setActiveTopbarItem] = useState(null);

  const groupAccess = process.env.REACT_APP_GROUP_ACCESS.split(",");

	const user = useContext(UserContext);
	const global = useContext(AcaaContext);
	let topbarItemClick = false;
	let menuClick = false;
	let configClick = false;

  const menu = [
    {
      label: 'Pages',
      icon: 'pi pi-fw pi-star-o',
      items: [
        { label: 'Map', icon: 'pi pi-fw pi-globe', to: '/' },
        { label: 'Cards', icon: 'pi pi-fw pi-th-large', to: '/cards' },
      ],
    },
  ];

  const routers = [
    { path: '/', element: <StatusMapAGC />, },
    { path: '/cards', element: <SensorCardsAGC /> },
  ];

  const onDocumentClick = () => {
    if (!menuClick) {
      if (isHorizontal() || isSlim()) {
        setMenuActive(false);
      }
      hideOverlayMenu();
    }

    if (configActive && !configClick) {
      setConfigActive(false);
    }

    menuClick = false;
    configClick = false;
  };

  const onMenuClick = () => {
    menuClick = true;
  };

  const onMenuitemClick = (event) => {
    if (!event.item.items) {
      hideOverlayMenu();
    }
    if (!event.item.items && (isSlim() || isHorizontal())) {
      setMenuActive(false);
    }
  };

  const onRootMenuitemClick = () => {
    setMenuActive((prev) => !prev);
  };

  const onMenuButtonClick = (event) => {
    menuClick = true;
    if (menuMode === 'overlay') {
      setOverlayMenuActive((prev) => !prev);
    } else if (isDesktop()) {
      setStaticMenuDesktopInactive((prev) => !prev);
      global.setMenuActive((prev) => !prev);
    } else {
      setStaticMenuMobileActive((prev) => !prev);
    }
    event.preventDefault();
  };
  const onTopbarMenuButtonClick = (event) => {
	topbarItemClick = true;
	setTopbarMenuActive((prevVal) => !prevVal);
	hideOverlayMenu();
	event.preventDefault();
};

  const hideOverlayMenu = () => {
    setOverlayMenuActive(false);
    setStaticMenuMobileActive(false);
  };

  const isDesktop = () => window.innerWidth > 1024;
  const isHorizontal = () => menuMode === 'horizontal';
  const isSlim = () => menuMode === 'slim';

  const isMenuVisible = () => {
    if (isDesktop()) {
      return menuMode === 'static' ? !staticMenuDesktopInactive : menuMode === 'overlay'?overlayMenuActive:true;
    } else {
		if (staticMenuMobileActive)
			return true;
		else if (menuMode === 'overlay')
			return overlayMenuActive;
		else
			return true;
	}
  };

  const onTopbarItemClick = (e) => {
    topbarItemClick = true;

		if (activeTopbarItem === e.item) setActiveTopbarItem(null);
		else setActiveTopbarItem(e.item);

		e.originalEvent.preventDefault();
  };

  const containerClassName = classNames('layout-wrapper', {
    'menu-layout-static': menuMode !== 'overlay',
    'menu-layout-overlay': menuMode === 'overlay',
    'layout-menu-overlay-active': overlayMenuActive,
    'menu-layout-slim': menuMode === 'slim',
    'menu-layout-horizontal': menuMode === 'horizontal',
    'layout-menu-static-inactive': staticMenuDesktopInactive,
    'layout-menu-static-active': staticMenuMobileActive,
  });

  const menuClassName = classNames('layout-menu-container', {
    'layout-menu-dark': false,
    'layout-menu-container-inactive': !isMenuVisible(),
  });

  return (
    <div className={containerClassName} onClick={onDocumentClick}>
      <div className="layout-wrapper-content">
        <AppTopBar
          activeUser={user}
          onMenuButtonClick={onMenuButtonClick}
          onTopbarItemClick={onTopbarItemClick}
          activeTopbarItem={activeTopbarItem}
        />
            <>
              <div className={menuClassName} onClick={onMenuClick}>
                <div className="menu-scroll-content">
                  <AppMenu
                    model={menu}
                    menuMode={menuMode}
                    active={menuActive}
                    onMenuitemClick={onMenuitemClick}
                    onRootMenuitemClick={onRootMenuitemClick}
                  />
                </div>
              </div>
              <div className="layout-main">
                <Routes>
                  {routers.map((router, index) => (
                    <Route key={`router${index}`} path={router.path} element={router.element} />
                  ))}
                </Routes>
              </div>
              <div className="layout-mask modal-in"></div>
              </>
        {/* <AppFooter /> */}
      </div>
    </div>
  );
};

export default App;
