import React, { useState, useEffect, useRef } from 'react'
import { Tooltip } from 'primereact/tooltip';
const HOUR_PERCENT = 14.28
const MINUTE_PERCENT = 0.23

export const AcaaTimeline = (props) => {
	const [startTime, setStartTime] = useState();
	const [timeArray, setTimeArray] = useState();
	const [markers, setMarkers] = useState([]);

	useEffect(() => {
		buildTimeline()
		const intervalId = setInterval(() => buildTimeline(), 5 * 60 * 1000)
		return () => clearInterval(intervalId);
	}, []);
	useEffect(() => {
		let markerArray = []
		if (startTime && props.schedule) {
			let i = 0;
			let itemList = props.schedule.sort(function (a, b) {
				return new Date(b.date) - new Date(a.date);
			});
			for (let flight of itemList) {
				let flightDate = new Date(flight.date)
				markerArray.push(
					<span>
						<Tooltip target={"#marker" + i} />
						<div id={"marker" + i} style={{ left: calcMarkerPlacement(flightDate), backgroundColor: flight.color, cursor: 'pointer' }} className='timestamp'
							data-pr-tooltip={(flight.arriving == true ? "Arriving" : "Departing") + "\n" + flight.ident + " " + flight.type + "\n" + formatTime(flightDate)}
							data-pr-position='left'
							data-pr-at="right center"
							data-pr-my="left center"
							onMouseEnter={(e) => {
								console.log(e)
								let list = document.getElementsByClassName("timestamp");
								for (let index = 0; index < list.length; ++index) {
									list[index].style.zIndex = "4";
								}
								e.target.style.zIndex = "5"
							}}
						>

							{

								flight.arriving == true ? <img src="assets/icons/icon-flights-white.png" alt="Arriving" style={{ marginLeft: '2px', marginTop: '2px' }} height="14px" width="14px" className="rotate180" /> : <img src="assets/icons/icon-flights-white.png" alt="Departing" style={{ marginLeft: '2px', marginTop: '2px' }} height="14px" width="14px" />
							}

							{/*<i style={{color:'#FFFFFF', marginLeft:'2px',marginTop:'2px'}} className={"pi " +  flight.icon}/>
							<div className={'timestamp-text' + (i % 2 == 0 ? '' : '-bottom') }>
								{flight.ident} {flight.type} 
							</div>*/}
						</div>
					</span>)
				i++;
			}

			setMarkers(markerArray)


		}

	}, [startTime, props.schedule])

	const buildTimeline = () => {
		let now = new Date()
		now.setHours(now.getHours() - 1)
		let timeArray = [];
		let amPm = 'am';
		if (now.getHours() > 12) {
			amPm = 'pm'
		}
		setStartTime(now);
		let time = now.getHours() % 12;
		if (time == 0) {
			time = 12
		}
		for (let i = 0; i <= 7; i++) {
			if (time % 12 == 0) {
				if (amPm === 'pm') {
					amPm = 'am'
				}
				else {
					amPm = 'pm'
				}
			}
			let timeSpan = <span className='time'>{time % 12 == 0 ? (12 + amPm) : ((time % 12) + amPm)}</span>
			time++
			timeArray.push(timeSpan);
		}
		setTimeArray(timeArray)
	}
	const formatTime = (time) => {
		let amPm = time.getHours() >= 12 ? 'PM' : 'AM'
		let hour = time.getHours() % 12 == 0 ? '12' : time.getHours() % 12
		let min = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()
		return hour + ':' + min + ' ' + amPm
	}
	const calcMarkerPlacement = (time) => {
		let start = new Date(startTime);
		if (time.getHours() < start.getHours() || time.getHours() > start.getHours() + 6) {
			return -200;
		}
		let i = 0;

		while ((start.getHours() < time.getHours())) {
			start.setHours(start.getHours() + 1);
			i++;
		}
		let hourPer = i * HOUR_PERCENT
		let minPer = time.getMinutes() * MINUTE_PERCENT;
		return hourPer + minPer + '%';

	}
	return (
		<div className='acaa-timeline'>
			<div className='line-box'>
				<div className='timestamps'>
					{markers}
				</div>
				<div className='line' />

			</div>
			<div className='times'>
				{timeArray}
			</div>
		</div>
	);
}